import React, { useState } from "react";
import ArrowAnimation from "../arrow-animation/ArrowAnimation";
import redirectIcon from "../../assets/goto.png";
import "../../page/lightning/turbine-manager/turbine-manager.css";

export default function TurbineManagerTable({
  filteredTurbines,
  selectedTurbineIds,
  handleHeaderCheckboxChange,
  handleRowCheckboxChange,
  toggleSort,
  sortField,
  sortOrder,
  sortTurbines,
  seeMoreTurbineIds,
  setSeeMoreTurbineIds,
  getIconForTurbine,
  renderManagedTurbines,
  toPrettyDateStr,
}) {
  const [subSortField, setSubSortField] = useState("timestamp");
  const [subSortOrder, setSubSortOrder] = useState("ASC");

  const toggleSubSort = (field) => {
    if (subSortField === field) {
      setSubSortOrder(subSortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSubSortField(field);
      setSubSortOrder("ASC");
    }
  };

  return (
    <div className="table-container">
      <table className="turbine-manager-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={filteredTurbines.every((t) =>
                  selectedTurbineIds.includes(t.id)
                )}
                onChange={handleHeaderCheckboxChange}
              />
            </th>
            <th className="cursor-pointer" onClick={() => toggleSort("name")}>
              Name{" "}
              {sortField === "name" ? (sortOrder === "ASC" ? "↓" : "↑") : "↑↓"}
            </th>
            <th>Status</th>
            <th
              className="cursor-pointer"
              onClick={() => toggleSort("distance")}
            >
              Distance{" "}
              {sortField === "distance"
                ? sortOrder === "ASC"
                  ? "↓"
                  : "↑"
                : "↑↓"}
            </th>
            <th
              className="cursor-pointer"
              onClick={() => toggleSort("probability")}
            >
              Probability{" "}
              {sortField === "probability"
                ? sortOrder === "ASC"
                  ? "↑"
                  : "↓"
                : "↑↓"}
            </th>
            <th>Outside IEC</th>
            <th>Model</th>
            <th>Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredTurbines.sort(sortTurbines).map((t, i) => {
            const sortedTurbineLightning = [...t.turbine_lightning].sort(
              (a, b) => {
                if (subSortField === "timestamp") {
                  const formattedA = `${toPrettyDateStr(
                    new Date(a.timestamp),
                    true
                  )}.${Math.round(a.nanosecond / 1e6)
                    .toString()
                    .padStart(3, "0")}`;
                  const formattedB = `${toPrettyDateStr(
                    new Date(b.timestamp),
                    true
                  )}.${Math.round(b.nanosecond / 1e6)
                    .toString()
                    .padStart(3, "0")}`;
                  return subSortOrder === "ASC"
                    ? formattedA.localeCompare(formattedB)
                    : formattedB.localeCompare(formattedA);
                }

                const compareA = a[subSortField];
                const compareB = b[subSortField];
                return subSortOrder === "ASC"
                  ? compareA - compareB
                  : compareB - compareA;
              }
            );

            return (
              <React.Fragment key={t.id}>
                <tr className="turbine-row">
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedTurbineIds.includes(t.id)}
                      onChange={(e) => handleRowCheckboxChange(e, t.id, i)}
                    />
                  </td>
                  <td className="name-column">
                    <div
                      className="turbine-name-pill"
                      data-full-name={t.name.length > 7 ? t.name : null}
                      onClick={() => {
                        window.open(
                          `/lassie/windfarm/${t.windfarm_id}/turbine/${t.id}`,
                          "_blank"
                        );
                      }}
                    >
                      <p>{t.name}</p>
                      <img src={redirectIcon} className="clear-icon" />
                    </div>
                  </td>
                  <td>
                    <img
                      style={{ height: "1.5em" }}
                      src={getIconForTurbine(t, false)}
                    />
                  </td>
                  {renderManagedTurbines(
                    t,
                    `${
                      Math.round(
                        Math.min(
                          ...t.turbine_lightning.map((l) =>
                            parseFloat(l.distance_km)
                          )
                        ) * 100
                      ) * 10
                    } m`
                  )}
                  {renderManagedTurbines(
                    t,
                    `${Math.round(t.probability_of_atleast_one * 100)} %`
                  )}
                  {renderManagedTurbines(
                    t,
                    t.turbine_lightning.some((tl) => tl.outside_certification)
                      ? "✔"
                      : "",
                    "value-checkmark"
                  )}
                  <td>{t.model}</td>
                  {t.user_comment ? (
                    <td className="comment" data-full-comment={t.user_comment}>
                      {t.user_comment}
                    </td>
                  ) : (
                    <td>{t.user_comment}</td>
                  )}
                  {t.turbine_lightning.length !== 0 ? (
                    <td>
                      <ArrowAnimation
                        showMore={seeMoreTurbineIds.indexOf(t.id) !== -1}
                        setShowMore={(value) => {
                          if (seeMoreTurbineIds.indexOf(t.id) === -1) {
                            setSeeMoreTurbineIds((prev) => [...prev, t.id]);
                          } else {
                            setSeeMoreTurbineIds((prev) =>
                              prev.filter((p) => p !== t.id)
                            );
                          }
                        }}
                      />
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>

                {seeMoreTurbineIds.indexOf(t.id) !== -1 && (
                  <>
                    <tr className="lightning-table">
                      <th></th>
                      <th
                        className="cursor-pointer"
                        onClick={() => toggleSubSort("distance_km")}
                      >
                        Distance{" "}
                        {subSortField === "distance_km"
                          ? subSortOrder === "ASC"
                            ? "↑"
                            : "↓"
                          : "↑↓"}
                      </th>
                      <th
                        className="cursor-pointer"
                        onClick={() => toggleSubSort("semi_major_axis")}
                      >
                        Semi major axis{" "}
                        {subSortField === "semi_major_axis"
                          ? subSortOrder === "ASC"
                            ? "↑"
                            : "↓"
                          : "↑↓"}
                      </th>
                      <th
                        className="cursor-pointer"
                        onClick={() => toggleSubSort("peak_current")}
                      >
                        Peak current{" "}
                        {subSortField === "peak_current"
                          ? subSortOrder === "ASC"
                            ? "↑"
                            : "↓"
                          : "↑↓"}
                      </th>
                      <th
                        className="cursor-pointer"
                        onClick={() => toggleSubSort("probability_of_hit")}
                      >
                        Probability{" "}
                        {subSortField === "probability_of_hit"
                          ? subSortOrder === "ASC"
                            ? "↑"
                            : "↓"
                          : "↑↓"}
                      </th>
                      <th>Outside IEC</th>
                      <th>Subsequent stroke</th>
                      <th
                        className="cursor-pointer"
                        onClick={() => toggleSubSort("timestamp")}
                      >
                        Timestamp{" "}
                        {subSortField === "timestamp"
                          ? subSortOrder === "ASC"
                            ? "↑"
                            : "↓"
                          : "↑↓"}
                      </th>
                      <th></th>
                    </tr>

                    {sortedTurbineLightning.map((tl) => (
                      <tr
                        className={`lightning-row ${tl.outside_certification
                            ? "lightning-outside-cert"
                            : ""
                          }`}
                        key={tl.id}
                      >
                        <td></td>
                        <td>{Math.round(tl.distance_km * 100) * 10} m</td>
                        <td>{Math.round(tl.semi_major_axis * 100) * 10} m</td>
                        <td>{Math.round(tl.peak_current * 10) / 10} kA</td>
                        <td>{Math.round(tl.probability_of_hit * 100)} %</td>
                        <td className="value-checkmark">
                          {tl.outside_certification ? "✔" : ""}
                        </td>
                        <td className="value-checkmark">
                          {tl.parent_id !== null ? "✔" : " "}
                        </td>
                        <td className="lightning-date">
                          {toPrettyDateStr(new Date(tl.timestamp), true)}.
                          {Math.round(tl.nanosecond / 1e6)
                            .toString()
                            .padStart(3, "0")}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
