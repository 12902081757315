import { http } from "../api";

const easyUnpack = ({ data }) => data;
export const WdTurbineApi = {
  create_turbine: {
    f: function create_turbine(windfarm_id, rdspp, turbine_name, blade_length, inspection_date, { valid_to = null }) {
      let url = '/wd/turbines';
      return http.post(url, {
        windfarm_id,
        rdspp,
        turbine_name,
        blade_length,
        inspection_date,
        valid_to
      }).then(easyUnpack);
    }
  },
  delete_turbine: {
    f: function delete_turbine(turbine_id) {
      let url = '/wd/turbines/$turbine_id';
      let parameterNames = ["$turbine_id"];
      [turbine_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.delete(url, { data: {} }).then(easyUnpack);
    }
  },
  get_turbine_by_id: {
    f: function get_turbine_by_id(turbine_id) {
      let url = '/wd/turbines/$turbine_id';
      let parameterNames = ["$turbine_id"];
      [turbine_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: {} }).then(easyUnpack);
    }
  },
};
