import { http } from "../api";

const easyUnpack = ({ data }) => data;
export const WdDefectApi = {
  base_view: {
    f: function base_view() {
      let url = '/wd/defects/';
      return http.get(url, { params: {} }).then(easyUnpack);
    }
  },
  list_blade_defects: {
    f: function list_blade_defects(turbine_id, blade, { inspection_date = null }) {
      let url = '/wd/defects/turbine/$turbine_id/blade/$blade';
      let parameterNames = ["$turbine_id", "$blade"];
      [turbine_id, blade].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: { inspection_date } }).then(easyUnpack);
    }
  },
  list_turbine_defects: {
    f: function list_turbine_defects(turbine_id) {
      let url = '/wd/defects/turbine/$turbine_id';
      let parameterNames = ["$turbine_id"];
      [turbine_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: {} }).then(easyUnpack);
    }
  },
  list_windfarm_defects: {
    f: function list_windfarm_defects(windfarm_id, { inspection_date = null }) {
      let url = '/wd/defects/windfarm/$windfarm_id';
      let parameterNames = ["$windfarm_id"];
      [windfarm_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: { inspection_date } }).then(easyUnpack);
    }
  },
  get_defect_details: {
    f: function get_defect_details(defect_id) {
      let url = '/wd/defects/$defect_id/details';
      let parameterNames = ["$defect_id"];
      [defect_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: {} }).then(easyUnpack);
    }
  },
  get_defect_image_position: {
    f: function get_defect_image_position(report_defect_id) {
      let url = '/wd/defects/$report_defect_id/position';
      let parameterNames = ["$report_defect_id"];
      [report_defect_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: {} }).then(easyUnpack);
    }
  },
  update_defect: {
    f: function update_defect(defect_id, {
      customer_note = null,
      downtime_note = null,
      budget_note = null,
      mark_for_repair = null,
      repaired = null,
      ignored = null
    }) {
      let url = '/wd/defects/$defect_id';
      let parameterNames = ["$defect_id"];
      [defect_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.patch(url, {
        customer_note,
        downtime_note,
        budget_note,
        mark_for_repair,
        repaired,
        ignored
      }).then(easyUnpack);
    }
  },
  update_defect_admin: {
    f: function update_defect_admin(defect_id, {
      distance = null,
      severity = null,
      defect_type = null,
      surface = null,
      repair_action = null,
      repair_comment = null
    }) {
      let url = '/wd/defects/admin/$defect_id';
      let parameterNames = ["$defect_id"];
      [defect_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.patch(url, {
        distance,
        severity,
        defect_type,
        surface,
        repair_action,
        repair_comment
      }).then(easyUnpack);
    }
  },
  create_defect: {
    f: function create_defect(turbine_id, defect, image_source, {
      defect_image_data = null,
      blade_image_data = null
    }) {
      let url = '/wd/defects';
      return http.post(url, {
        turbine_id,
        defect,
        image_source,
        defect_image_data,
        blade_image_data
      }).then(easyUnpack);
    }
  },
  list_defects_by_report_defect_id: {
    f: function list_defects_by_report_defect_id(report_defect_id) {
      let url = '/wd/defects/report_defect_id/$report_defect_id';
      let parameterNames = ["$report_defect_id"];
      [report_defect_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: {} }).then(easyUnpack);
    }
  },
};
