import React, { useState, useCallback } from 'react';
import { turbineAPI } from "../../../api";
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";
import TurbineAnimation from '../../../components/turbine-animation/TurbineAnimation';
import AlertPopup from '../../../components/alert-popup/AlertPopup';
import './delete-wd-turbine.css'

export default function DeleteWdTurbine(props) {
  const [turbineID, setTurbineID] = useState('');
  const [turbine, setTurbine] = useState('');
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const fetchTurbine = useCallback(async () => {
    setLoading(true);
    if (turbineID) {
      try {
        const turbineData = await turbineAPI.getTurbine(turbineID);
        setTurbine(turbineData);
      } catch (error) {
        console.error("Error fetching turbine:", error);
      }
    }
    setLoading(false);
  }, [turbineID]);


  const deleteTurbine = () => {
    turbineAPI.deleteTurbine(turbineID)
    setTurbine(null);
    setShowAlert(false)
  };

  const handleDeleteTurbine = () => {
    setShowAlert(true)
  }


  return (
    <div className='delete-wd-turbine-container'>

      {loading && <TurbineAnimation />}

      {showAlert && <AlertPopup
        showPopup={showAlert}
        closePopup={setShowAlert}
        status={"warning"}
        title={"Are you sure you want to delete the turbine?"}
        buttonText={"No"}
        buttonStatus={'wrong'}
        buttonText2={'Yes'}
        buttonStatus2={'success'}
        action2={deleteTurbine}
      >
      </AlertPopup>}

      <div className="delete-turbine-input-wrapper">
        <WplInput
          value={turbineID}
          type="number"
          placeholder={'Enter turbine ID...'}
          onChanged={(v) => setTurbineID(v || "")}
        />
        <WplButton value="Fetch Turbine" onClick={fetchTurbine} />
      </div>

      <div>
        {turbine && <table className='delete-wd-turbine-table'>
          <thead>
            <tr>
              <th>id</th>
              <th>windfarm</th>
              <th>name</th>
              <th>inspection date</th>
              <th>defects</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
       
              <tr key={turbine.id}>
                <td>{turbine.id}</td>
                <td>{turbine.windfarm}</td>
                <td>{turbine.name}</td>
                <td>{turbine.inspection_date}</td>
                <td>{turbine.defect_count}</td>
                <td> <buttion className='delete-wd-turbine-btn' onClick={handleDeleteTurbine}>
                  Delete
                </buttion></td>
              </tr>
          </tbody>
        </table>}
      </div>
    </div>
  );
}
