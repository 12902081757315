import React, { useState } from 'react';
import { defectAPI } from '../../../api';
import WplInput from '../../../components/wpl-input/WplInput';
import WplButton from '../../../components/wpl-button/WplButton';
import AlertPopup from '../../../components/alert-popup/AlertPopup';
import TurbineAnimation from '../../../components/turbine-animation/TurbineAnimation';
import './edit-wd-defects.css';

export default function EditWDDefects() {
    const [defectID, setDefectID] = useState('');
    const [defectData, setDefectData] = useState([]);
    const [editableDefect, setEditableDefect] = useState(null);
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertData, setAlertData] = useState({ status: '', })

    const fetchDefect = async () => {
        setLoading(true)
        try {
            const defect = await defectAPI.listDefectByReportID(defectID);
            setLoading(false)
            setDefectData(defect);
            if (defect.length > 0) {
                setEditableDefect(defect[0]);
            }
        } catch (error) {
            setLoading(false)
            console.log('Error fetching defect:', error);

        }
    };

    const handleInputChange = (field, value) => {
        setEditableDefect((prev) => ({
            ...prev,
            [field]: value,
        }));
    };
    const updateDefect = async () => {
        setLoading(true)
        const { id, distanceFromHub, severity, defectType, surface, repairRecommendation, repairComment } = editableDefect;
        const payload = {
            distance: parseFloat(parseFloat(distanceFromHub).toFixed(2)),
            severity,
            defect_type: defectType,
            surface,
            repair_action: repairRecommendation,
            repair_comment: repairComment
        };

        try {
            await defectAPI.updateDefectAdmin(id, payload);
            setLoading(false)
            setShowAlert(true)
            setAlertData({ status: 'success', title: 'Defect updated successfully!' })
            setDefectData((prevData) =>
                prevData.map((defect) =>
                    defect.id === id
                        ? { ...defect, distanceFromHub, severity, defectType, surface, repairRecommendation, repairComment }
                        : defect
                )
            );
        } catch (error) {
            setLoading(false)
            console.log('Error updating defect:', error.response || error);
            setShowAlert(true)
            setAlertData({ status: "wrong", title: "Something went wrong..." })
        }
    };

    return (
        <div className="edit-wd-defects-container">
            {loading && <TurbineAnimation />}
            {showAlert && <AlertPopup
                showPopup={showAlert}
                closePopup={setShowAlert}
                status={alertData.status}
                title={alertData.title}
                buttonText={"Close"}
            >
            </AlertPopup>}
            <div className="defect-input-wrapper">
                <WplInput
                    value={defectID}
                    placeholder={'Enter defect ID...'}
                    onChanged={(v) => setDefectID(v)}
                />
                <WplButton value="Fetch Defect" onClick={fetchDefect} />
            </div>

            {defectData.length > 0 && <div>
                <table className="edit-wd-defects-table">
                    <thead>
                        <tr>
                            <th>Defect ID</th>
                            <th>Turbine</th>
                            <th>Windfarm</th>
                            <th>Blade</th>
                            <th>Dist from hub (m)</th>
                            <th>Severity</th>
                            <th>Defect type</th>
                            <th>Surface</th>
                            <th>Repair action</th>
                            <th>Repair comment</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {defectData.map((d, i) => (
                            <tr key={i}>
                                <td>{d.reportDefectId}</td>
                                <td>{d.turbineName}</td>
                                <td>{d.windfarm}</td>
                                <td>{d.blade}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={
                                            editableDefect?.id === d.id
                                                ? editableDefect.distanceFromHub
                                                : d.distanceFromHub
                                        } onChange={(e) => handleInputChange('distanceFromHub', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <div className={`severity-select severity-level-${editableDefect?.id === d.id ? editableDefect.severity : d.severity}`}>
                                        <select
                                            value={editableDefect?.id === d.id ? editableDefect.severity : d.severity}
                                            onChange={(e) => handleInputChange('severity', parseInt(e.target.value))}
                                            className={`severity-level-${editableDefect?.id === d.id ? editableDefect.severity : d.severity}`}
                                        >
                                            {[1, 2, 3, 4, 5].map((level) => (
                                                <option key={level} value={level} className={`severity-level-${level}`} >
                                                    {level}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={editableDefect?.id === d.id ? editableDefect.defectType : d.defectType}
                                        onChange={(e) => handleInputChange('defectType', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={editableDefect?.id === d.id ? editableDefect.surface : d.surface}
                                        onChange={(e) => handleInputChange('surface', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={editableDefect?.id === d.id ? editableDefect.repairRecommendation : d.repairRecommendation}
                                        onChange={(e) => handleInputChange('repairRecommendation', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        className='repair-comment'
                                        value={editableDefect?.id === d.id ? editableDefect.repairComment : d.repairComment}
                                        onChange={(e) => handleInputChange('repairComment', e.target.value)}
                                    />
                                </td>
                                <td>
                                    {editableDefect?.id === d.id && (
                                        <WplButton value="Save" onClick={updateDefect} />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </div>
    );
}
