import React, { useRef, useState } from "react";
import clear from "../../assets/img/clear-24px.svg";
import successIcon from "../../assets/img/success.png";
import wrongIcon from "../../assets/img/wrong.png";
import warningIcon from "../../assets/img/warning.png";
import "./alert-popup.css";

export default function AlertPopup({
  children,
  showPopup,
  closePopup,
  status,
  title,
  buttonText,
  buttonStatus,
  action,
  buttonText2,
  buttonStatus2,
  action2
}) {
  const me = useRef(null);

  const [downTarget, setDownTarget] = useState("");

  if (!showPopup) return <div></div>;

  const statusIcons = {
    success: successIcon,
    wrong: wrongIcon,
    warning: warningIcon,
  };

  return (
    <div
      ref={me}
      className="alert-popup"
      onMouseDown={(e) => setDownTarget(e.target)}
      onMouseUp={(e) => {
        if (e.target !== me.current) return;
        if (downTarget !== me.current) return;
        closePopup(false);
      }}
    >
      <img
        className="close-popup-cross-btn"
        onClick={() => closePopup(false)}
        src={clear}
        alt="close popup"
      />
      <div
        className={`popup-wrapper ${status}`}
        onMouseDown={(e) => setDownTarget(e.target)}
      >
        <div className="status-container">
          <img
            src={statusIcons[status] || warningIcon}
            className="status-icon"
            alt={`${status} icon`}
          />
          <h3>{title}</h3>
        </div>

        <div className="text-container">{children}</div>
        <div className="btn-container">
          {buttonText && (
            <button
              className={`alert-btn ${buttonStatus ? buttonStatus : status}`} 
              onClick={action ? action : () => closePopup(false)}
            >
              {buttonText}
            </button>
          )}
          {buttonText2 && (
            <button
              className={`alert-btn ${buttonStatus2}`}
              onClick={action2 ? action2 : () => action2()}
            >
              {buttonText2}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
