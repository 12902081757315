import React, { useState, useContext, useCallback } from "react";
import { LassieContext } from "../../context/LassieProvider";
import TurbineLightningRuler from "../turbine-lightning-ruler/TurbineLightningRuler";
import { toPrettyDateStr } from "../../prettyDate";
import { getIconForTurbine } from "../ol-map/icons";
import redirectIcon from "../../assets/img/goto.png";

export default function MapPopup({
  selectedTurbine,
  layerSettings,
  showLightningTable,
  setShowLightningTable,
  showSensorTable,
  setShowSensorTable
}) {
  const { createUserAction } = useContext(LassieContext);
  const [hoveredStrike, setHoveredStrike] = useState(null);

  const [sortField, setSortField] = useState("distance_km");
  const [sortOrder, setSortOrder] = useState("ASC");

  const handleStrikeHover = useCallback((strike) => {
    setHoveredStrike(strike);
  }, []);

  const handleNameClick = () => {
    window.open(
      `/lassie/windfarm/${selectedTurbine.windfarm_id}/turbine/${selectedTurbine.id}`,
      "_blank"
    );
  };

  const fetchCreateUserAction = ({
    windfarm_id,
    turbine_ids,
    action,
    user_comment
  }) => {
    createUserAction({
      turbine_ids,
      action,
      target_date: null,
      user_comment: null
    });
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(field);
      setSortOrder("ASC");
    }
  };

  const sortStrikes = useCallback(
    (a, b) => {
      let result = 0;
      switch (sortField) {
        case "distance_km":
          result = a.distance_km - b.distance_km;
          break;
        case "peak_current":
          result = a.peak_current - b.peak_current;
          break;
        case "timestamp":
          result = new Date(a.timestamp) - new Date(b.timestamp);
          break;
        default:
          result = 0;
      }
      return sortOrder === "ASC" ? result : -result;
    },
    [sortField, sortOrder]
  );

  if (!selectedTurbine) return null;

  return (
    <>
      {selectedTurbine && (
        <div className="selected-turbine">
          <div className="title-wrapper">
            <div
              className="turbine-name-pill"
              data-full-name={
                selectedTurbine.name.length > 7 ? selectedTurbine.name : null
              }
              onClick={() => handleNameClick()}
            >
              <p>{selectedTurbine.name}</p>
              <img src={redirectIcon} className="popup-icon" />
            </div>

            <div className="status-icon-wrapper">
              <img
                className="turbine-status-icon"
                title={`Turbine ${selectedTurbine.status.replace(/_/g, " ")}`}
                src={getIconForTurbine(
                  selectedTurbine,
                  layerSettings.most_probable
                )}
                alt={selectedTurbine.status}
              />
            </div>
          </div>
          <div className="ruler-container">
            <TurbineLightningRuler
              strikes={selectedTurbine.turbine_lightning
                .filter(
                  (tl) => !layerSettings.most_probable || tl.most_probable
                )
                .filter(
                  (tl) =>
                    !layerSettings.only_show_outside_iec ||
                    tl.outside_certification
                )}
              onStrikeHover={handleStrikeHover}
              onMouseLeave={() => setHoveredStrike(null)}
            />
          </div>

          <div className="map-pop-up-table-container">
            <div
              className={`table-scroll ${
                selectedTurbine.ping_measurements?.length > 0 &&
                showLightningTable
                  ? "sensor-table"
                  : "full-height-table"
              }`}
            >
              <table className="styled-table">
                <thead>
                  <tr>
                    <th
                      className="cursor-pointer"
                      onClick={() => toggleSort("distance_km")}
                    >
                      Distance{" "}
                      {sortField === "distance_km"
                        ? sortOrder === "ASC"
                          ? "↓"
                          : "↑"
                        : "↑↓"}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => toggleSort("peak_current")}
                    >
                      Peak{" "}
                      {sortField === "peak_current"
                        ? sortOrder === "ASC"
                          ? "↓"
                          : "↑"
                        : "↑↓"}
                    </th>
                    <th>IEC</th>
                    <th
                      className="cursor-pointer"
                      onClick={() => toggleSort("timestamp")}
                    >
                      Date{" "}
                      {sortField === "timestamp"
                        ? sortOrder === "ASC"
                          ? "↓"
                          : "↑"
                        : "↑↓"}
                    </th>
                    {selectedTurbine.ping_measurements?.length > 0 && (
                      <th>
                        <div
                          onClick={() => setShowLightningTable((prev) => !prev)}
                          className={`pop-up-arrow-icon ${
                            showLightningTable ? "open" : "closed"
                          }`}
                        ></div>
                      </th>
                    )}
                  </tr>
                </thead>
                {showLightningTable && (
                  <tbody>
                    {[...selectedTurbine.turbine_lightning]
                      .filter(
                        (tl) => !layerSettings.most_probable || tl.most_probable
                      )
                      .filter(
                        (tl) =>
                          !layerSettings.only_show_outside_iec ||
                          tl.outside_certification
                      )
                      // .sort((a, b) => a.distance_km - b.distance_km)
                      .sort(sortStrikes)
                      .map((tl) => (
                        <tr
                          className={`popup-row ${
                            tl.outside_certification ? "outside-cert" : ""
                          } ${
                            hoveredStrike && hoveredStrike.id === tl.id
                              ? "highlighted"
                              : ""
                          }`}
                          key={tl.id}
                        >
                          <td>{Math.round(tl.distance_km * 100) * 10} m</td>
                          <td>{Math.round(tl.peak_current * 10) / 10} kA</td>
                          <td>{tl.outside_certification ? "✔" : ""}</td>
                          <td>
                            {toPrettyDateStr(new Date(tl.timestamp), true)}
                          </td>
                          {selectedTurbine.ping_measurements?.length > 0 && (
                            <td></td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>

            {selectedTurbine.ping_measurements?.length > 0 && (
              <div
                className={`table-scroll ${showSensorTable && "sensor-table"} `}
              >
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Sensor measurement</th>
                      <th>
                        <div
                          onClick={() => setShowSensorTable((prev) => !prev)}
                          className={`pop-up-arrow-icon ${
                            showSensorTable ? "open" : "closed"
                          }`}
                        ></div>
                      </th>
                    </tr>
                  </thead>
                  {showSensorTable && (
                    <tbody>
                      {selectedTurbine.ping_measurements.map((pm, i) => (
                        <tr key={i} className="popup-row">
                          <td>{toPrettyDateStr(new Date(pm), true)}</td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            )}
          </div>

          <div className="btn-container">
            <button
              className="action-btn mark-for-inspection-btn"
              disabled={
                selectedTurbine.status === "marked_for_inspection" ||
                selectedTurbine.status === "acceptable_risk"
              }
              onClick={() => {
                fetchCreateUserAction({
                  windfarm_id: selectedTurbine.windfarm_id,
                  turbine_ids: [selectedTurbine.id],
                  action: "marked_for_inspection",
                  user_comment: ""
                });
              }}
            >
              Mark for inspection
            </button>
            <button
              className="action-btn accept-risk-btn"
              disabled={selectedTurbine.status === "acceptable_risk"}
              onClick={() => {
                fetchCreateUserAction({
                  windfarm_id: selectedTurbine.windfarm_id,
                  turbine_ids: [selectedTurbine.id],
                  action: "acceptable_risk",
                  user_comment: ""
                });
              }}
            >
              Accept risk
            </button>
          </div>
        </div>
      )}
    </>
  );
}
