import { http } from "../api";
const easyUnpack = ({ data }) => data;
export const LassieDashboardApi = {
  dashboard_statistics: {
    f: function dashboard_statistics(
      from_date,
      to_date,
      { windfarm_id = null }
    ) {
      let url = "/lassie/dashboard/statistics";
      return http
        .get(url, { params: { from_date, to_date, windfarm_id } })
        .then(easyUnpack);
    },
  },
  dashboard_turbine_statistics: {
    f: function dashboard_turbine_statistics(
      from_date,
      to_date,
      { windfarm_id = null }
    ) {
      let url = "/lassie/dashboard/turbine-statistics";
      return http
        .get(url, { params: { from_date, to_date, windfarm_id } })
        .then(easyUnpack);
    },
  },
  dashboard_date_range: {
    f: function dashboard_date_range({ windfarm_id = null }) {
      let url = "/lassie/dashboard/date-range";
      return http.get(url, { params: { windfarm_id } }).then(easyUnpack);
    },
  },
  dashboard_scenario_statistics: {
    f: function dashboard_scenario_statistics(from_date, to_date, windfarm_id) {
      let url = "/lassie/dashboard/scenario-statistics";
      return http
        .get(url, { params: { from_date, to_date, windfarm_id } })
        .then(easyUnpack);
    },
  },
  get_seasonality_statistics: {
    f: function get_seasonality_statistics(windfarm_id) {
      let url = "/lassie/dashboard/seasonality-statistics";
      return http.get(url, { params: { windfarm_id } }).then(easyUnpack);
    },
  },
};
